@font-face {
  font-family: 'RobotoSlab';
  src: local('RobotoSlab'), url(./Fonts/RobotoSlab-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoLight';
  src: local('LatoLight'), url(./Fonts/Lato-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoBold';
  src: local('LatoBold'), url(./Fonts/Lato-Bold.ttf) format('truetype');
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,700&display=swap');
body {
  margin: 0;
  font-family: 'Lora' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
