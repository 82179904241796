@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,700&display=swap);
@font-face {
  font-family: 'RobotoSlab';
  src: local('RobotoSlab'), url(/static/media/RobotoSlab-Bold.a88e016d.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoLight';
  src: local('LatoLight'), url(/static/media/Lato-Light.bd895b1e.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoBold';
  src: local('LatoBold'), url(/static/media/Lato-Bold.401bd636.ttf) format('truetype');
}
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Lora' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  font-family: 'Raleway';
}
p, li{
  color: #1f4c5d
}
#fundoAzul img{
display: none;
}
h1{
  font-size: calc(10px + 5vw);
  color: #014c29;
  font-family: 'Raleway';
  
}
h2{
  font-size: calc(16px + 2vw);
  font-family: 'Raleway';
  color: #1f4c5d !important;
}
h3{
  font-size: calc(16px + 1vw) !important;
  font-family: 'Raleway';
  font-weight: bold;
  color: #88b02c;
}
.Footer{
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #1f4c5d;
}
.Footer img{
  width: auto;
}
.footerContent{
  margin-left: auto;
}
h4{
  font-size: 1.2em;
  color: #1f4c5d;
  font-weight: 700;
  font-family: 'Raleway';

}
li{
  list-style-type: none;
  font-size: calc(10px + 1vw)
  
}
p{
  font-family: 'Raleway';
  font-size: calc(6px + 0.8vw) !important;
}
#backgroundConsulta{
  background-color: #e5f1f3;
  background-image: url(/static/media/backgroundContato.469b3db7.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
#imagesResponsivas{
  display: none;
}
.navbar-header {
  float: left;
  padding: 15px;
  text-align: center;
  width: 100%;
}
.navbar-brand {float:none;}
/* navbar */
.navbackground{
  height: 55vw;
  width: 100vw;
  background-color: #e5f1f3 !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/static/media/headerbackground.90736b9d.svg);
}
.backgroundDeOndeVem{
  height: 57vw;
  background-color:#e5f1f3 !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/static/media/onde_back.f2c09404.svg);
}
.backgroundDeOndeVem p{
  color: white !important;
}
#logoMobile{
  display: none !important;
}
.carrouselImage{
  width: auto;
  height: 30vw;
}
#saborImage{
  vertical-align: bottom;
  width: 30vw;
}
#Sabor{
  height: 23vw;
}
.Salina{
  text-align: start;
  margin-left: 5%;
  width: 40%;
}
#contatoMobile{
  display: none;
}
.saboresImage{
  width: auto;
  height: 30vw;
}
#lojaMobile{
  display: none !important;
}
#maranataMobile{
  display: none;
}
.navbar-light .navbar-nav .nav-link{
  font-size: calc(6px + 0.6vw) !important;
  color: #1f4c5d;
}
.bg-light {
  background-color: transparent!important;
}
 /* atendimento */

.reverse{
  flex-direction: row-reverse !important;
}
  #lojaMobile{
    display: none;
  }

#mycarrousel{
  padding-left: 15vw;
  padding-right: 15vw;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(/static/media/Lato-Bold.401bd636.ttf) format('opentype');
}
#nossasLinhas{
  height: 10vw !important;
}
#deondeWeb{
  display: flex !important;
  height: 6vw !important;
  margin-bottom: 10px;
}
.teste2{
  display: none;
}
@media only screen and (max-width: 1000px) {
  #contatoMobile p{
    font-size: 3vw !important;

  }
  
  #contatoMobile .px-5, #contatoMobile .mx-5 {
    margin: 0px !important;
    padding: 0px !important;
  }
  #contatoWeb{
    display: none !important;
  }
  .offset-1{
    margin-left: 0px !important;
  }

  #contatoMobile img{
    height: 20vw;
  }
  #contatoMobile{
    display: block;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
  }
  #navbarPadding{
    margin-top: 600px !important;
  }
  .bg-light{
    background-color: whitesmoke !;
  }
  .consultaP {
    padding-right: 10%;
    padding-left: 10% !important;
    text-align: center !important;
  }
  #iconsConsulta{
    justify-content: center !important;
    margin-left: 20px;
  }
  .navbar-brand{
    display: none;
  }
  .mt-5{
    margin: 10px !important;
  }
  .consultaP img{
    width: 30vw;
  }
  #nossaLinhaImage{
    width: 30vw;
    margin-top: 20px;
    margin-bottom: 30px;
    align-self: center;
  }
  .order-2{
    order: 1;
  }
  #saborImage{
    width: 50vw;
  }
  #marginSabores{
    padding-left: 10%;
    padding-right: 10%;
  }
  #marginSabores p{
    text-align: center !important;
    font-size: calc(12px + 0.8vw) !important;
    
  }
  .order-1{
    order: 2;
  }
  #lojaMobile{
    width: 100%;
  }
  .w-75{
    width: 100% !important;
  }
  .w-50{
    width: 100% !important;
  }
  .pr-5{
    padding-right: 0px;
  }
  .pt-5{
    padding-top: 0px !important;
  }
  .pb-5{
    padding-bottom: 0px !important;
  }
  .mb-5{
    margin-bottom: 0px !important;
  }
  .mr-5{
    margin-right: 0px !important;
  }

  .ml-5{
    margin-left: 0px !important;
  }
  .py-5{
    padding-top: 0px !important;
  }
  .pl-5{
    padding-left: 0px !important;
  }
  body{
    text-align: justify;
  }

  h1, h2, h3, h4{
    padding-left: 10% !important;
    padding-right: 10% !important;
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
  }

  li{
    font-size: calc(6px + 3vw) !important;
  }

  a img{
    width: 50%;
    height: 100%;
    padding-left: 0px !important;
    margin-left: 0px !important;

  }



  .reducedImage{
    width: 80% !important;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .Borda{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #title{
    padding-bottom: 20px !important;
  }

  #centerAbordagem{
    margin-left: 15px !important;
  }
  /* atendimento */
  #fotoBackground{
    padding-top: 30px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 60%, rgba(138,177,48,1) 60%);
    margin-bottom: 60px;
  }
  #consultaImage{
    width: 80% !important;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  #backgroundConsulta{
    padding-top: 20% !important;
    background-color: #e8f1f4;
    background-image: url(/static/media/backgroundContato.469b3db7.svg);
  }
  .whiteBackground{
    background-color: transparent;
  }
  #borderAtendimento{
    border-left: 5px solid #8ab130;
  }
  .consultaP{
    text-align: start;
    margin-left: 0px !important;
  }
  .consultaP p, .consultaP h3,  h4{
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .consultaP p {
    font-size: 16px !important;
  }
  .px-5{
    padding-right: 0px;
  }

  #fixedbutton {
    position: fixed !important;
    width: 60px !important;
    height: 60px !important;
    bottom: 20px !important;
    right: 0px !important; 
    z-index: 1 !important;
  }
  #fixedbuttonTel {
    display: inline !important;
    position: fixed !important;
    width: 60px !important;
    height: 60px !important;

    bottom: 100px !important;
    right: 0px !important; 
    z-index: 1 !important;
  }

 
  #melhorForma{
    font-size: calc(12px + 2vw) !important;
    padding-right: 10% !important;

  }

}
@media only screen and (max-width: 700px) {
  #lojaMobile{
    display: flex !important;
    width: 100% !important;
  }
  #deondeWeb{
    display: none !important;
  }
  #loja{
    margin-top: 100vw !important;
    padding-left: 15% !important;
    padding-right: 15% !important;

  }
  #loja #lojaImage{
    width: 100%;
    height: 200vw !important;
  }
  #loja p{
    font-size: 3vw !important;
  }
  #nossasLinhas{
    height: 20vw !important;
    margin-bottom: 30px;
  }
  #mycarrousel{
    padding-left: 0;
    padding-right: 0;
  }
  #Linhas{
    padding-top: 10% !important;
  }
  #mycarrousel .carrouselImage{
    height: 50vw !important;
  }
  .Footer img{
    height: auto !important;
    width: 100px;
    text-align: center;
    align-self: center;
    margin-bottom: 20px !important;
  }
  #maranataMobile{
    display: block;
  }
  #seloImage{
    display: none;
  }
  #fundoAzul img{
    display: block;
  }
  #Sabor{
    height: 40vw;
  }
  #Maranata p{
    font-size: 3vw !important;
  }
  #Maranata #textos .pb-4{
    padding-bottom: 0px !important;
  }
  #Maranata #seloImage{
    width: 330vw;
  }
  #Maranata #textos{
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
  }
  .Salina h4{
    text-align: center !important;
  }
  .Salina{
    width: 100% !important;
    text-align: justify;
    margin-left: 15%;
    margin-right: 15%;
    padding-bottom: 20% !important;
  }
  #loja{
    margin-top: 110vw !important;
  }
  #logoMobile{
    display: block !important;
  }
  #lojaMobile{
    padding-top: 0vw;
    display: block;
  }

  .backgroundDeOndeVem p{
    font-size: 3vw !important;
    color: #344d5d !important;
  }
  #lojaWeb{
    display: none;
  }
  ul{

    background-color: whitesmoke;
  }
  .navbar-light .navbar-nav .nav-link{
    font-size: calc(12px + 0.6vw) !important;

  }
  .navbackground{
    width: auto !important;
    height: 115vh;
    background-size: contain;
    background-image: url(/static/media/navbackgroundmobile.fb03c55e.svg);
  }
  #imagesResponsivas{
    display: block;
    background-color: #e5f1f3;

  }
  .backgroundDeOndeVem{
    background-color: #e8f1f4 !important;
    background-image: none;
  }
  #fundoAzul{
    background-color: #e8f1f4 !important;
  }
  .textoOndeVem{
    height: 70vw !important;
    width: 100vw !important;
  }
  #navbarPadding{
    margin-top: 25vh !important;
  }
}
@media only screen and (max-width: 680px) {
  .navbackground{
    height: 110vh;
 }
}
@media only screen and (max-width: 650px) {
  .navbackground{
    height: 140vw;
 }
}

